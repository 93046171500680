import { Component } from './component';
import { ElementMutationObserver } from './element-mutation-observer';
import { IdGenerator, DefaultIdGenerator } from './id-generator';

type ComponentId = number;
export type CssSelector = string;
type ComponentClass = new (host: HTMLElement, id: ComponentId) => Component;

export class ComponentRegistry {
  private static idGenerator: IdGenerator<ComponentId>;
  private static mutationObserver: ElementMutationObserver;

  private static registrations = new Map<CssSelector, ComponentClass>();
  private static instances = new Map<ComponentId, Component>();

  static setIdGenerator(idGenerator: IdGenerator<ComponentId>) {
    this.idGenerator = idGenerator;
  }

  static declare(selector: CssSelector, componentClass: ComponentClass) {
    this.registrations.set(selector, componentClass);
    this.register(Array.from(document.querySelectorAll<HTMLElement>(selector)));
  }

  static create(host: HTMLElement, componentClass: ComponentClass): Component {
    const id = this.idGenerator.next();
    host.dataset.componentId = `${id}`;
    const instance = new componentClass(host, id);

    this.instances.set(id, instance);

    return instance;
  }

  static get<T extends Component>(id: ComponentId): T {
    return this.instances.get(id) as T;
  }

  static registerChildren(root: HTMLElement) {
    const createdInstances: Component[] = [];
    this.registrations.forEach((component: ComponentClass, selector: CssSelector) => {
      const hostElements = Array.from(root.querySelectorAll<HTMLElement>(selector));
      for (const element of hostElements) {
        if (element.dataset.componentId === undefined) {
          createdInstances.push(this.create(element, component));
        }
      }
    });
    // After ALL components were created
    window.requestAnimationFrame(() => {
      for (const instance of createdInstances) {
        instance.onInit();
      }
    });
  }

  static register(hostElements: HTMLElement[]) {
    const createdInstances: Component[] = [];

    this.registrations.forEach((component: ComponentClass, selector: CssSelector) => {
      for (const element of hostElements) {
        // only register on elements that haven't already been used and that match selector
        if (
          element.dataset.componentId === undefined &&
          element.matches(selector)
        ) {
          createdInstances.push(this.create(element, component));
        }
        // Check for components in childs of appended element.
        this.registerChildren(element);
      }
    }
    );
    // After ALL components were created
    window.requestAnimationFrame(() => {
      for (const instance of createdInstances) {
        instance.onInit();
      }
    });
  }

  static observe(observerRoot: HTMLElement = document.body) {
    this.mutationObserver = new ElementMutationObserver(observerRoot);

    this.mutationObserver.onElementsAdded((addedElements: HTMLElement[]) => this.register(addedElements));
  }
}

// NOTE: Just the default behavior for auto setup whene this lib is used.
// can be changed per project if nessesary
ComponentRegistry.setIdGenerator(new DefaultIdGenerator());
ComponentRegistry.observe();

window.onload = () => {
  // Register all elements on load.
  ComponentRegistry.registerChildren(document.body);
}

// NOTE: This is for debugging purposes. With this you can access componentRegistry in the console.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.componentRegistry = ComponentRegistry;
