import { Component } from '../../../libs/components';

export default class SliderComponent extends Component {
    rangeInput = this.host.querySelector<HTMLInputElement>('.slider__input');
    rangeTrack = this.host.querySelector<HTMLElement>('.slider__track');
    rangeThumb = this.host.querySelector<HTMLElement>('.slider__thumb');
    input = document.getElementById(this.host.getAttribute('data-input-id')) as HTMLInputElement;
    minText = this.host.querySelector<HTMLElement>('.slider-min');
    defaultPrice = 650000;
    onInit() {
        this.rangeInput.addEventListener('input', (event) => {
            const target = event.target as HTMLInputElement;
            const value: number = parseFloat(target.value);
            this.handleSlider(value);
            this.handlers.forEach(handler => handler());
        });
        this.input.addEventListener('blur', (event) => {
            const target = event.target as HTMLInputElement;
            const value: number = parseFloat(target.value);
            this.handleSlider(value);
            this.handlers.forEach(handler => handler());
        });
        this.input.addEventListener('focus', () => {
            this.input.value = this.rangeInput.value.toString();
        });
        this.handleSlider(this.defaultPrice);
    }
    handleSlider = (value: number) => {
        const min: number = parseFloat(this.rangeInput.min);
        const max: number = parseFloat(this.rangeInput.max);
        value = Math.round(value / 5000) * 5000;

        const percentage: number = ((value - min) / (max - min)) * 100;

        this.rangeTrack.style.width = `${percentage}%`;
        this.rangeThumb.style.left = `${percentage}%`;
        this.rangeThumb.style.transform = `translate(calc(${-percentage}% + 2px), calc(-50% + 2px))`;
        this.input.value = this.formatNumber(value);

        this.rangeInput.value = value.toString();

    }
    formatNumber = (number: number) => {
        const formatted = new Intl.NumberFormat('de-CH', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(number);

        return formatted;
    }
    getValue = () => {
        return parseFloat(this.rangeInput.value);
    }
    setMin = (min: number) => {
        this.rangeInput.min = min.toString();
        this.handleSlider(this.getValue());
        this.minText.innerText = 'Min. ' + this.formatNumber(min);
    }
    handlers: Array<() => void> = [];
    registerHandler = (callback: () => void) => {
        this.handlers.push(callback);
    }
}
SliderComponent.declare('.slider');