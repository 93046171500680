import { Component } from "../../../libs/components";


class BlogAuthorsBlockExtenderComponent extends Component {
    onInit(): void {
        this.host.addEventListener('click', event => {
            event.preventDefault();
            this.toggleVisibility();
        });
    }

    toggleVisibility() {
        const contentTargetSelector = this.host.getAttribute('data-target');
        const contentTarget = document.getElementById(contentTargetSelector);
        contentTarget.classList.remove('blog__container__authors--limited');
        this.host.remove();
    }
}
BlogAuthorsBlockExtenderComponent.declare('.blog__container__authors__extender');