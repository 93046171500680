import Glide from '@glidejs/glide'
import { Component } from '../../../libs/components';

class GlideScrollerComponent extends Component {
    glideComponent;

    onInit(): void {
        const componentId = this.host.getAttribute('data-component-id');
        this.glideComponent = new Glide(`[data-component-id="${componentId}"].glide`, {
            type: 'carousel',
            startAt: 0,
            perView: 1
        });

        if (window.innerWidth < 768) {
            this.glideComponent.mount();
            this.glideComponent.on('run.after', () => {
                this.glideHandleHeight();
            });
            this.glideHandleHeight();
        }

        window.addEventListener('resize', () => {
            if (window.innerWidth > 768 && this.host.classList.contains('glide--carousel')) {
                this.glideComponent.destroy();
                this.glideHandleHeight();
            }
        });

        const image = this.host.querySelector('.quote__img') as HTMLImageElement;
        if (image && image.complete) {
            this.glideHandleHeight();
        } else if (image) {
            // if image is not loaded, wait for it to load
            image.addEventListener('load', this.glideHandleHeight.bind(this));
            image.addEventListener('error', this.glideHandleHeight.bind(this));
        }
    }

    glideHandleHeight() {
        const activeSlide = this.host.querySelector('.glide__slide--active') as HTMLElement;
        const activeSlideHeight = activeSlide ? activeSlide.offsetHeight : 0;

        const glideTrack = this.host.querySelector('.glide__track') as HTMLElement;
        const glideTrackHeight = glideTrack ? glideTrack.offsetHeight : 0;

        if (activeSlideHeight !== glideTrackHeight && window.innerWidth < 768) {
            glideTrack.style.height = `${activeSlideHeight}px`;
        } else if (window.innerWidth >= 768) {
            glideTrack.style.height = `auto`;
        }
    }

}
GlideScrollerComponent.declare('.glide-scroller');