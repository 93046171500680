import { scrollAnimatedTo } from '../../common/scroll-to-anchor/scroll-animated-to';
import { Component } from '../../../libs/components';

export class ScrollToButtonComponent extends Component {
    static hasGlobalListeners = false;
    buttons = this.host.querySelectorAll('a[href^="#"]');


    onInit(): void {
        this.buttons.forEach(button => {
            button.addEventListener('click', (event) => {

                const clickTarget = event.currentTarget as HTMLAnchorElement;
                const clickTargetHref = clickTarget.getAttribute('href');
                let targetEl;

                if (clickTargetHref) {
                    targetEl = document.querySelector(
                        clickTargetHref.replace(/ /g, '\\ ')
                    );
                }

                if (targetEl) {
                    event.preventDefault();
                    scrollAnimatedTo(targetEl as HTMLElement);
                    history.pushState(null, null, clickTargetHref);
                }
            });
        });
    }

}

ScrollToButtonComponent.declare('.scroll-to-buttons');
