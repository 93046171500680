import { Component } from '../../../libs/components';

export default class TooltipComponent extends Component {
  private tooltip: HTMLElement;
  private icon: HTMLElement;
  private body: HTMLElement;
  private isOpen: boolean;

  constructor(protected readonly host: HTMLElement) {
    super(host);

    this.tooltip = host;
    this.icon = host.querySelector('.tooltip__icon') as HTMLElement;
    this.body = host.querySelector('.tooltip__body') as HTMLElement;

    this.icon.addEventListener('click', this.toggleTooltip.bind(this));
    document.addEventListener('click', this.closeTooltip.bind(this));
  }

  toggleTooltip() {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.tooltip.classList.add('tooltip-open');
    } else {
      this.tooltip.classList.remove('tooltip-open');
    }
  }

  closeTooltip(event: MouseEvent) {
    if (this.isOpen && !this.tooltip.contains(event.target as Node)) {
      this.isOpen = false;
      this.tooltip.classList.remove('tooltip-open');
    }
  }
  onInit(): void {
    this.isOpen = false;
  }
}

TooltipComponent.declare('.tooltip');
