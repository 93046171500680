import { Component } from '../../../libs/components';


class TypewriterComponent extends Component {
    /**
     * Must use single quotes for data-typewriter attribute in HTML code:
     * <div data-typewriter='["Hello", "World"]'></div>
     */
    currentWordIdx = 0;
    words: string[];
    timeout = 2000;
    typerSpeed = 50;

    onInit() {
        this.words = JSON.parse(this.host.getAttribute('data-typewriter'));

        setTimeout(() => {
            this.typeWordByIndex(this.currentWordIdx);
        }, this.timeout);

        this.lockParentHeight();
    }

    lockParentHeight() {
        /**
         * Sets a min-height to the parent element to prevent vertical layout shift.
         */
        const parentEl = this.host.parentElement;
        const parentHeight = Math.floor(parentEl.getBoundingClientRect().height);

        parentEl.style.minHeight = `${parentHeight}px`;
    }

    get nextWordIdx(): number {
        return (this.currentWordIdx + 1) % this.words.length;
    }

    typeWordByIndex(wordIdx: number) {
        const deletionDuration = this.host.innerText.length * this.typerSpeed;
        const pauseDuration = this.typerSpeed * 10;
        const typingDuration = (this.words[wordIdx].length * this.typerSpeed) + pauseDuration;
        const totalDuration = deletionDuration + typingDuration;

        this._manipulateText('remove', this.host.innerText);

        setTimeout(() => {
            this._manipulateText('add', this.words[wordIdx]);
        }, (deletionDuration + pauseDuration));

        setTimeout(() => {
            this.lockParentHeight();
            this.typeWordByIndex(this.nextWordIdx);
            this.currentWordIdx = this.nextWordIdx;
        }, (totalDuration + this.timeout));
    }

    _manipulateText(variant: 'add' | 'remove', text?: string) {
        const textLength = text.length;
        const speed = this.typerSpeed;

        if (variant === 'add') {
            for (let i = 0; i < textLength; i++) {
                setTimeout(() => {
                    this.host.innerText = text.slice(0, i + 1);
                }, i * speed);
            }
        } else if (variant === 'remove') {
            for (let i = textLength; i > 0; i--) {
                setTimeout(() => {
                    this.host.innerText = text.slice(0, i - 1);
                }, (textLength - i) * speed);
            }
        }
    }
}
TypewriterComponent.declare('[data-typewriter]')