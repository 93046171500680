export function scrollAnimatedTo(targetElement: HTMLElement) {
    const rect = targetElement.getBoundingClientRect();
    const nav = document.querySelector<HTMLElement>('header .nav');
    const navStyle = window.getComputedStyle(nav);
    let offset = nav.getBoundingClientRect().height;
    if (navStyle.position !== 'fixed') {
        const pageTitleBlock = document.querySelector<HTMLElement>("header .page-title-block");
        if (pageTitleBlock) {
            offset = parseFloat(window.getComputedStyle(pageTitleBlock).marginTop);
        }
    }
    const scrollTop = document.documentElement.scrollTop;
    const newScrollTop = rect.top + scrollTop - offset;

    window.scrollTo({
        top: newScrollTop,
        behavior: 'smooth'
    });

    if (targetElement.classList.contains('open-overlay-button')) {
        targetElement.dispatchEvent(new Event('click'));
    }
}