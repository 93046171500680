import { scrollAnimatedTo } from "./scroll-animated-to";
(() => {
    if (window.location.hash) {
        const targetEl = document.querySelector(window.location.hash);
        window.onload = function () {
            scrollAnimatedTo(targetEl as HTMLElement);
        };
    }

    if ('onhashchange' in window) {
        window.addEventListener('hashchange', (event) => {
            const hash = `#${event.newURL.split("#")[1]}`;
            const targetEl = document.querySelector(hash);

            if (targetEl) {
                scrollAnimatedTo(targetEl as HTMLElement);
            }
        });
    }
})();