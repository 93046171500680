import { Component } from "../../../libs/components";


class BlogTableComponent extends Component {
    onInit(): void {
        const numTd = this.host.querySelector('tr').querySelectorAll('td').length;

        if (numTd > 3) {
            this.host.classList.add('blog-table-scrollable');
        }
    }
}
BlogTableComponent.declare('body.blog table');