/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component } from "../../../libs/components";

declare function Gradient(config: object): void;

class AnimatedHeaderComponent extends Component {
    canvasId: string;

    onInit(): void {
        this.canvasId = `header-animation-${this.host.dataset.componentId}`;
        const canvasEl = document.createElement('canvas');
        canvasEl.setAttribute('id', this.canvasId);
        canvasEl.classList.add('header__canvas');
        this.host.appendChild(canvasEl);

        if (window.location.hostname == 'localhost') return;

        // Recreate gradient due to may changed header class (which affects coloring).
        const observer = new MutationObserver(this.createGradient.bind(this))
        observer.observe(this.host, {
            attributes: true
        });


        this.createGradient();
    }

    createGradient() {
        let colors = ['#591B30', '#FFFFFF', '#591B30', '#FFFFFF'];

        if (this.host.classList.contains('header--grey-image')) {
            colors = ['#3D4137', '#FFFFFF', '#575A52', '#FFFFFF'];
        } else if (this.host.classList.contains('header--blue')) {
            colors = ['#1C2742', '#FFFFFF', '#3E475E', '#FFFFFF'];
        } else if (this.host.classList.contains('header--red')) {
            colors = ['#591B30', '#FFFFFF', '#591B30', '#FFFFFF'];
        }

        if (this.host.classList.contains('header--home') && window.innerWidth < 768) {
            colors = ['#888986', '#FFFFFF', '#c1c1c1', '#FFFFFF'];
        }

        new Gradient({
            canvas: `#${this.canvasId}`,
            colors: colors,
            width: 1000,
            height: 720
        });
    }
}
AnimatedHeaderComponent.declare('.header--image');