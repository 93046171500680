import { DESKTOP_WIDTH, NAV_HEIGHT } from '../../constants';
import { Component } from '../../../libs/components';

export function handleMobileDropdown(dropdownEl: HTMLElement) {
  /**
   * Invoked when a .nav__button-link is clicked which is 
   * child of a .dropdown element. 
   * This function handles the opening / closing of .dropdown__content.
   */
  const openClass = "dropdown--open";
  if (dropdownEl.classList.contains(openClass)) {
    // Close the clicked dropdown.
    dropdownEl.classList.remove(openClass);
  } else {
    // Close all open dropdowns, then open the one clicked.
    const parent = dropdownEl.closest('.nav__content');
    parent.querySelectorAll(`.${openClass}`).forEach((openDropdownEl: HTMLElement) => {
      openDropdownEl.classList.remove(openClass);
    });
    dropdownEl.classList.add(openClass);
  }
}

export default class NavComponent extends Component {
  nav = this.host;
  animatingTimeout;
  previousScroll = 0;


  showStickyNav() {
    const navHeight = document.querySelector('.nav').clientHeight;
    const scrollMaxY = window.scrollMaxY || (document.documentElement.scrollHeight - document.documentElement.clientHeight)

    if (window.scrollY > NAV_HEIGHT && window.scrollY < this.previousScroll && window.scrollY < scrollMaxY) {
      this.nav.classList.add('nav--sticky');
      if (
        !this.nav.classList.contains('home-nav') &&
        window.screen.width >= DESKTOP_WIDTH
      ) {
        document.body.style.marginTop = `${navHeight}px`;
      }
    } else {
      this.nav.classList.remove('nav--sticky');
      if (
        !this.nav.classList.contains('home-nav') &&
        window.screen.width >= DESKTOP_WIDTH
      ) {
        document.body.style.marginTop = `0px`;
      }
    }

    this.previousScroll = window.scrollY;
  }

  toggleMobileNav() {
    /**
     * `mobileOpenDelayedClass` is added immediately after opening,
     * and delayed (for 500ms) after closing. It is used to set styles for
     * the closed state, but only after the closing animation is done.
     */
    const openClass = 'nav--show-mobile';
    const headerOpenClass = 'header--mobile-nav-open';
    const mobileOpenDelayedClass = 'nav--mobile-open-delayed';

    if (this.host.classList.contains(openClass)) {
      // will become closed
      this.host.closest('header').classList.remove(headerOpenClass);
      this.animatingTimeout = setTimeout(() => {
        this.host.classList.remove(mobileOpenDelayedClass);
      }, 500);
    } else {
      // will become open
      clearTimeout(this.animatingTimeout);
      this.host.classList.add(mobileOpenDelayedClass);
      this.host.closest('header').classList.add(headerOpenClass);
    }
    this.host.classList.toggle(openClass);
  }

  onInit() {
    this.showStickyNav();
    window.onscroll = () => this.showStickyNav();

    this.host.querySelectorAll('.nav__toggler').forEach((el: HTMLElement) => {
      el.addEventListener('click', this.toggleMobileNav.bind(this));
    });

    this.host.querySelectorAll('.nav__button-link').forEach((el: HTMLElement) => {
      el.addEventListener('click', () => {
        handleMobileDropdown(el.closest('.dropdown'));
      });
    });
  }
}

NavComponent.declare('.nav');
