import { Component } from "../../../libs/components"

export class LimiterButtonComponent extends Component {
    containerEl: HTMLElement;
    targetElements: NodeListOf<HTMLElement>;
    limitNumber: number;
    allElementsVisible = false;
    hiddenClass = 'hidden';

    onInit(): void {
        const containerElSelector = this.host.getAttribute('data-container');
        const targetElementsSelector = this.host.getAttribute('data-target');
        const dataLimit = this.host.getAttribute('data-limit');

        this.containerEl = document.querySelector(containerElSelector) as HTMLElement;
        this.targetElements = this.containerEl.querySelectorAll(targetElementsSelector);

        if (dataLimit !== null) {
            this.limitNumber = parseInt(dataLimit, 10);
        } else {
            console.error('data-limit attribute is missing');
        }

        if (this.limitNumber >= this.targetElements.length) {
            this.host.remove();
        }

        this.host.addEventListener('click', event => {
            event.preventDefault();
            this.toggleVisibility();
        });

        window.addEventListener('resize', () => {
            this.handleResize();
        });
        this.handleResize();
    }

    public handleResize(): void {
        if (window.innerWidth < 768 && this.host.getAttribute('data-device') == 'desktop') {
            this.showAll();
            this.host.classList.add(this.hiddenClass);
        } else if (this.limitNumber) {
            this.showNumberItems(this.limitNumber);
            this.host.classList.remove(this.hiddenClass);
        }
    }

    private toggleVisibility(): void {
        if (this.allElementsVisible) {
            this.showNumberItems(this.limitNumber);
        } else {
            this.showAll();
        }
    }

    showAll() {
        this.showNumberItems(this.targetElements.length);
    }

    showNumberItems(numberItems: number): void {
        this.targetElements.forEach((el, idx) => {
            if (idx < numberItems) {
                el.classList.remove(this.hiddenClass);
            } else {
                el.classList.add(this.hiddenClass);
            }
        });
        this.host.setAttribute('data-limiter', String(numberItems));

        this.allElementsVisible = numberItems >= this.targetElements.length;
        this.host.textContent = this.allElementsVisible ? this.host.getAttribute('data-alternative-text') : this.host.getAttribute('data-text');
    }
}
LimiterButtonComponent.declare('.limiter-button');