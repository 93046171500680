import { Component } from '../../../libs/components';

export class VideoOverlayComponent extends Component {
  background = this.host.querySelector('.video-overlay__background');
  startVideoButtons = document.querySelectorAll('.quote__start-video-button');
  closeButton = this.host.querySelector('.video-overlay__close-button');
  content = this.host.querySelector('.video-overlay__content');

  handleEscape = (event) => {
    if (event.key === "Escape") {
      this.closeVideoOverlay();
    }
  }
  openVideoOverlay = (videoUrl) => {
    this.showElement(this.background);
    this.showElement(this.closeButton);
    this.showElement(this.content);
    this.content.setAttribute('src', videoUrl);
    document.querySelector('html').classList.add('no-scroll');
    this.closeButton.addEventListener('click', this.closeVideoOverlay);
    document.addEventListener('keydown', this.handleEscape);
    this.background.addEventListener('click', this.closeVideoOverlay);
  }

  closeVideoOverlay = () => {
    this.hideElement(this.background);
    this.hideElement(this.closeButton);
    this.hideElement(this.content);
    document.querySelector('html').classList.remove('no-scroll');
    this.content.setAttribute('src', 'about:blank');
    this.closeButton.removeEventListener('click', this.closeVideoOverlay);
    this.background.removeEventListener('click', this.closeVideoOverlay);
    document.removeEventListener('keydown', this.handleEscape);
  }

  onInit(): void {
    this.startVideoButtons.forEach((button) => {
      button.addEventListener('click', (event) => {
        event.preventDefault();
        this.openVideoOverlay(button.getAttribute('data-video-url'))
      });
    });
  }
}

VideoOverlayComponent.declare('.video-overlay');
