import { Component, ComponentRegistry } from '../../../libs/components';
import SliderComponent from '../slider/slider';
import { SwitchButtonsComponent } from '../switch-buttons/switch-buttons';

export class HypothekCalculatorComponent extends Component {
    switchButtons = this.host.querySelector<HTMLElement>('.switch-buttons');
    switchButtonsComponent = ComponentRegistry.get<SwitchButtonsComponent>(parseInt(this.switchButtons.dataset.componentId));
    openCalculatorButton = this.host.querySelector<HTMLElement>('.open-calculator-button');
    priceInput = this.host.querySelector<HTMLInputElement>('.price-input');
    slider = this.host.querySelector<HTMLElement>('.slider');
    sliderComponent = ComponentRegistry.get<SliderComponent>(parseInt(this.slider.dataset.componentId));
    buyPriceLabel = this.host.querySelector<HTMLElement>('.buy-price__label');
    hypothekPriceLabel = this.host.querySelector<HTMLElement>('.hypothek-price__label');

    onInit() {
        this.switchButtonsComponent.registerHandler(this.update);
        this.sliderComponent.registerHandler(this.update);
    }

    get currentLanguage() {
        const langValue = document.documentElement.lang;
        return langValue.split('-')[0];
    }

    update = () => {
        const mode = this.switchButtonsComponent.getSelectedValue();
        let min = 125000;
        let path = 'new-home';
        if (mode === 'buy') {
            this.buyPriceLabel.classList.remove('hidden');
            this.hypothekPriceLabel.classList.add('hidden');
        }
        else {
            this.buyPriceLabel.classList.add('hidden');
            this.hypothekPriceLabel.classList.remove('hidden');
            min = 100000;
            path = 'prolongation';
        }
        this.sliderComponent.setMin(min);
        const selectedPrice = this.sliderComponent.getValue();

        this.openCalculatorButton.setAttribute('href', `https://descartes.swiss/hypo-app/?path=${path}&marketValue=${selectedPrice}&lang=${this.currentLanguage}`);
    }
}

HypothekCalculatorComponent.declare('.hypothek-calculator');