import { Component } from '../../../libs/components';


class TableSwitchButtonsComponent extends Component {
  onInit(): void {
    this.host.querySelectorAll('.pillow-scroll-list__item__link').forEach((item: HTMLElement) => {
      item.addEventListener('click', event => {
        event.preventDefault();
        this.clickButton((item.getAttribute('data-cell')) as 'left' | 'right');
      });
    });
  }

  clickButton(column: 'left' | 'right') {
    const tableEl = document.getElementById('anlagemodelle-table') as HTMLTableElement;

    this.host.querySelectorAll('.pillow-scroll-list__item__link').forEach((item: HTMLElement) => {
      item.classList.remove('pillow-scroll-list__item__link--active');
      if (item.dataset.cell === column) {
        item.classList.add('pillow-scroll-list__item__link--active');
      }
    });

    tableEl.classList.remove('right', 'left');
    tableEl.classList.add(column);
  }
}
TableSwitchButtonsComponent.declare('.pillow-scroll-list--anlagemodelle');