import { Component } from '../../../libs/components';

type Value = string | number;

interface TableProduct {
  name: string;
}

interface TableModelColumn {
  name: string;
  tooltip?: string;
  hideDesktop?: boolean;
  cssClass?: string;
}

interface TableModelRow {
  name: string;
  values: Value[];
}

interface TableModel {
  name: string;
  columns: TableModelColumn[];
  rows: TableModelRow[];
}

interface TableLabel {
  name: string;
  tooltip?: string;
}

interface Table {
  product: TableProduct;
  models: TableModel[];
  label: TableLabel;
}

export default class TableComponent extends Component {
  tables: Table[];
  selectedProduct: number;
  selectedModel: number;

  buildButtons() {
    const buttonsContainer = document.createElement('div');
    buttonsContainer.classList.add('button-list');
    this.tables.forEach((table, index) => {
      const product = table.product.name;
      const button = document.createElement('button');
      button.id = `button-list__button-${index}`;
      button.innerText = product;
      button.classList.add('button-list__button');
      button.addEventListener('click', () => this.onClick(index));
      buttonsContainer.appendChild(button);
    });
    this.host.appendChild(buttonsContainer);
  }

  updateButtons() {
    this.tables.forEach((t, index) => {
      const button = document.getElementById(`button-list__button-${index}`);
      if (index === this.selectedProduct)
        button.classList.add('button-list__button--active');
      else button.classList.remove('button-list__button--active');
    });
  }

  updateModels() {
    const rerender = () => {
      this.updateModels();
      this.updateTable();
    };

    const oldSelectContainer = document.querySelector('#table__select');
    if (oldSelectContainer) oldSelectContainer.remove();

    const selectContainer = document.createElement('div');
    selectContainer.id = 'table__select';

    const selectWrapper = document.createElement('div');
    const selectElement = document.createElement('div');
    const labelElement = document.createElement('label');
    const arrowElement = document.createElement('div');

    selectContainer.classList.add('select');
    selectWrapper.classList.add('select__wrapper');
    labelElement.innerText = this.tables[this.selectedProduct].label.name;

    const hasTooltip = this.tables[this.selectedProduct].label.tooltip;

    if (hasTooltip) {
      const tooltip = document.createElement('div');
      tooltip.classList.add('tooltip');
      tooltip.innerHTML = `
      <div class="tooltip__icon">
        <div class="icon icon--info" />
      </div>
      <div class="tooltip__body tooltip__body--left-bottom">
        ${hasTooltip}
      </div>
      `;
      labelElement.appendChild(tooltip);
    }

    arrowElement.classList.add('select__arrow');
    selectElement.classList.add('select__element');
    selectElement.innerText =
      this.tables[this.selectedProduct].models[this.selectedModel].name;

    const options = document.createElement('div');
    options.classList.add('select__options');

    const product = this.tables[this.selectedProduct];
    product.models.forEach((model, index) => {
      const option = document.createElement('div');
      option.classList.add('select__options__option');
      option.innerText = model.name;
      option.addEventListener('click', () => {
        this.selectedModel = index;
        rerender();
      });

      if (index === this.selectedModel)
        option.classList.add('select__options__option--active');

      options.appendChild(option);
    });

    selectWrapper.appendChild(selectElement);
    selectWrapper.appendChild(options);
    selectWrapper.appendChild(arrowElement);
    selectContainer.appendChild(labelElement);
    selectContainer.appendChild(selectWrapper);
    this.host.appendChild(selectContainer);
  }

  updateTable() {
    const oldTables = document.querySelector('.table__tables');
    if (oldTables) oldTables.remove();

    const tablesElement = document.createElement('div');
    tablesElement.classList.add('table__tables');

    const product = this.tables[this.selectedProduct];
    const model = product.models[this.selectedModel];
    const labels = document.createElement('div');
    const columnTooltipsHTML = {};

    labels.classList.add('labels');
    model.columns.forEach((column, index) => {
      const label = document.createElement('label');
      label.innerText = column.name;

      if (column.tooltip) {
        const tooltip = document.createElement('div');
        const tooltipHTML = `
        <div class="tooltip__icon">
          <div class="icon icon--info"></div>
        </div>
        <div class="tooltip__body tooltip__body--left-top">
          ${column.tooltip}
        </div>
        `;
        tooltip.innerHTML = tooltipHTML;
        tooltip.classList.add('tooltip');

        columnTooltipsHTML[index] = tooltipHTML;
        label.appendChild(tooltip);
      }
      if (column.hideDesktop) label.classList.add('hide-desktop');
      if (column.cssClass) label.classList.add(column.cssClass);
      labels.appendChild(label);
    });
    tablesElement.appendChild(labels);

    model.rows.forEach((row) => {
      const rowElement = document.createElement('div');
      rowElement.classList.add('row');

      const rowNameElement = document.createElement('div');
      rowNameElement.classList.add('row__name');
      rowNameElement.innerText = row.name;

      const rowValuesContainer = document.createElement('div');
      rowValuesContainer.classList.add('row__container');

      row.values.forEach((value, index) => {
        const column = model.columns[index];
        const columnElement = document.createElement('div');
        columnElement.classList.add('row__value');
        if (column.cssClass) columnElement.classList.add(column.cssClass);
        columnElement.innerHTML = `
        <label>${column.name}</label>
        <span>${value}</span>
        `;

        if (columnTooltipsHTML[index]) {
          columnElement.innerHTML = `
          <label>${column.name}
          <div class="tooltip">
            <div class="tooltip__icon">
              <div class="icon icon--info"></div>
            </div>
            <div class="tooltip__body tooltip__body--mobile-center">
              ${column.tooltip}
            </div>
          </div>
          </label>
          <span>${value}</span>
          `;
        }

        if (column.hideDesktop) columnElement.classList.add('hide-desktop');

        rowValuesContainer.appendChild(columnElement);
      });

      rowElement.appendChild(rowNameElement);
      rowElement.appendChild(rowValuesContainer);
      tablesElement.appendChild(rowElement);
    });

    this.host.appendChild(tablesElement);
  }

  onClick(productId: number) {
    if (this.selectedProduct === productId) return;
    this.selectedProduct = productId;
    this.host.setAttribute('data-product-id', String(productId));
    this.updateButtons();
    this.updateModels();
    this.updateTable();
  }

  setData(data: Table[]) {
    this.tables = data;
    this.selectedModel = 0;

    this.buildButtons();
    this.onClick(0);
  }

  onInit() {
    const dataHolderEl = document.querySelector(`#${this.host.getAttribute('data-table-data-id')}`);
    const data = JSON.parse(dataHolderEl.textContent);
    this.setData(data);
  }
}

TableComponent.declare('.table');
