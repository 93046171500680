import { Component } from '../../../libs/components';

export class OverlayComponent extends Component {
  background = this.host.querySelector('.overlay__background');
  openOverlayButtons = document.querySelectorAll('.open-overlay-button');
  startVideoButtons = document.querySelectorAll('.quote__start-video-button');
  closeButton = this.host.querySelector('.overlay__close-button');
  container = document.querySelector('.overlay__container');
  content = this.host.querySelector('.overlay__content');
  startCalculator = document.querySelector('.start-calculator__form');

  handleEscape = (event) => {
    if (event.key === 'Escape') {
      this.closeOverlay();
    }
  };
  handleTransition = () => {
    if (this.container.classList.contains('active')) return;
    this.content.setAttribute('src', 'about:blank');
  };
  openOverlay = (url: string) => {
    this.closeButton.addEventListener('click', this.closeOverlay);
    document.addEventListener('keydown', this.handleEscape);
    this.background.addEventListener('click', this.closeOverlay);
    this.container.addEventListener('transitionend', this.handleTransition);

    this.showElement(this.closeButton);
    this.showElement(this.content);

    this.container.classList.add('active');
    this.content.setAttribute('src', url);
    document.querySelector('html').classList.add('no-scroll');
  };
  closeOverlay = () => {
    this.container.classList.remove('active');
    document.querySelector('html').classList.remove('no-scroll');

    this.closeButton.removeEventListener('click', this.closeOverlay);
    document.removeEventListener('keydown', this.handleEscape);
    this.background.removeEventListener('click', this.closeOverlay);
    this.container.removeEventListener('transitionend', this.handleTransition);
  };

  onInit(): void {
    this.openOverlayButtons.forEach((button) => {
      button.addEventListener('click', (event) => {
        event.preventDefault();
        if (button.classList.contains('disabled')) {
          return;
        }
        this.openOverlay(button.getAttribute('url') || button.getAttribute('href'));
      });
    });

    if (this.startCalculator) {
      this.startCalculator.addEventListener('submit', () => {
        if (!this.startCalculator.classList.contains('errors')) {
          this.openOverlay(
            this.startCalculator.querySelector('.button').getAttribute('url')
          );
        }
      });
    }
  }
}

OverlayComponent.declare('.overlay');
