import { Component } from '../../../libs/components';

export default class AccordionComponent extends Component {
  questions = this.host.querySelectorAll('.questions');

  revertToggle(toggle) {
    toggle.classList.toggle('questions-title__icon--down');
    toggle.classList.toggle('questions-title__icon--up');
    
  }

  onInit() {
    this.questions.forEach((question) => {
      const questionBody = question.querySelector('.question-section-body');
      questionBody.addEventListener('click', e => {
        e.stopPropagation();
      })
      question.addEventListener('click', () => {
        const toggle = question.querySelector('.questions-title__icon');
        const questionBody = question.querySelector('.question-section-body');

        if (questionBody.classList.contains('hidden')) {
          this.showElement(questionBody);
          this.revertToggle(toggle);
        } else {
          this.hideElement(questionBody);
          this.revertToggle(toggle);
        }
      });
    });
  }
}

AccordionComponent.declare('.accordion');
