import { Component } from '../../../libs/components';

export class StartCalculatorComponent extends Component {
  pillarButton = this.host.querySelector('.pillow-scroll-list__item__link[data-calculator="3A"]');
  permissiveButton = this.host.querySelector('.pillow-scroll-list__item__link[data-calculator="FZ"]');
  investButton = this.host.querySelector('.pillow-scroll-list__item__link[data-calculator="3B"]');
  header = document.querySelector('.header');
  pillarExplanation = this.host.querySelector('.pillar-explanation');
  permissiveExplanation = this.host.querySelector('.permissive-explanation');
  investExplanation = this.host.querySelector('.invest-explanation');
  birthDayGroup = this.host.querySelector('.birth-day-group');
  annualDepositGroup = this.host.querySelector('.annual-deposit-group');
  transferredCapitalGroup = this.host.querySelector(
    '.transferred-capital-group'
  );
  seedCapitalGroup = this.host.querySelector('.seed-capital-group');
  birthDayInput = this.host.querySelector('.birth-day-input');
  buttonBackground = this.host.querySelector('.button__background');
  showOverlayButton = this.host.querySelector(
    '.start-parametrized-calculator-button'
  ) as HTMLElement;
  showOverlayButtonNoParams = this.host.querySelector(
    '.start-calculator-button'
  ) as HTMLElement;
  investmentCategory = this.host.querySelector(
    '#investment-category'
  ) as HTMLInputElement;
  requiredInputs = this.host.querySelector(
    '#required-inputs'
  ) as HTMLInputElement;
  form = this.host.querySelector(
    'form.start-calculator__form'
  ) as HTMLFormElement;

  refreshCalculator() {
    this.header.classList.remove('header--grey-image');
    this.header.classList.remove('header--blue');
    this.header.classList.remove('header--red');
    this.pillarButton?.classList.remove('pillow-scroll-list__item__link--active');
    this.permissiveButton?.classList.remove('pillow-scroll-list__item__link--active');
    this.investButton?.classList.remove('pillow-scroll-list__item__link--active');
    this.hideElementIfExists(this.seedCapitalGroup);
    this.hideElementIfExists(this.annualDepositGroup);
    this.hideElementIfExists(this.birthDayGroup);
    this.hideElementIfExists(this.transferredCapitalGroup);
    this.hideElementIfExists(this.pillarExplanation);
    this.hideElementIfExists(this.permissiveExplanation);
    this.hideElementIfExists(this.investExplanation);
    this.investmentCategory.value = null;
  }
  hideElementIfExists = (element: Element) => {
    if (element) this.hideElement(element);
  };
  showElementIfExists = (element: Element) => {
    if (element) this.showElement(element);
  };

  onInit() {
    this.pillarButton?.addEventListener('click', () => {
      this.show3AFields();
    });
    this.permissiveButton?.addEventListener('click', () => {
      this.showFZFields();
    });
    this.investButton?.addEventListener('click', () => {
      this.show3BFields();
    });
    const selectedCalculatorType = this.investmentCategory.value;
    if (selectedCalculatorType === '3A') this.show3AFields();
    else if (selectedCalculatorType === 'FZ') this.showFZFields();
    else if (selectedCalculatorType === '3B') this.show3BFields();
    else this.show3AFields();
    this.form.addEventListener('submit', (event) => {
      event.preventDefault();

      if (!this.validateInputs()) {
        this.form.classList.add('errors');
        return this.showInputErrors();
      }

      this.form.classList.remove('errors');
    });

    this.form.querySelectorAll('input').forEach((input) => {
      input.addEventListener('input', () => {
        this.updateCalculatorUrl();
        this.showInputErrors(input);
      });
    });

    /**
     * Listen for change in the .select element, which is only 
     * displayed on mobile (as an alternative to the buttons used on desktop).
     */
    this.host.querySelector('.select').addEventListener('selectionChanged', (event: CustomEvent) => {
      switch (event.detail) {
        case '3A':
          this.show3AFields();
          break;
        case '3B':
          this.show3BFields();
          break;
        case 'FZ':
          this.showFZFields();
          break;
      }
    });
  }
  showInputErrors(input?) {
    if (!input) {
      const idsString = this.requiredInputs.value;
      const ids = idsString.split(',');
      ids.forEach((id) => {
        const input = document.getElementById(id) as HTMLInputElement;
        if (!this.isValidInput(id)) {
          input.classList.add('error');
        } else {
          input.classList.remove('error');
        }
      });

      return;
    }

    if (!this.isValidInput(input.id)) {
      input.classList.add('error');
    } else {
      input.classList.remove('error');
    }
  }
  validateInputs(): boolean {
    const idsString = this.requiredInputs.value;
    const ids = idsString.split(',');
    return ids.every((id) => this.isValidInput(id));
  }
  isValidInput(id: string): boolean {
    const value = this.getParameterValue(id)?.trim();
    if (value === '') return false;
    const element = document.getElementById(id) as HTMLInputElement;
    if (element.max && parseInt(value) > parseInt(element.max)) return false;
    return true;
  }
  updateCalculatorUrl() {
    const idsString = this.requiredInputs.value;
    const calculatorUrl = '/calculator/?' + this.getQueryParameters(idsString);
    this.showOverlayButton.setAttribute('url', calculatorUrl);
    const noParamsCalculatorUrl =
      '/calculator/?' + this.getQueryParameters('investment-category');
    this.showOverlayButtonNoParams.setAttribute('url', noParamsCalculatorUrl);
  }
  getQueryParameters(idsString: string): string {
    const ids = idsString.split(',');
    return ids
      .map((id) => {
        const value = this.getParameterValue(id);
        return encodeURIComponent(id) + '=' + encodeURIComponent(value);
      })
      .join('&');
  }
  getParameterValue(id: string): string {
    const element = document.getElementById(id) as HTMLInputElement;
    return element?.value;
  }
  show3AFields() {
    this.refreshCalculator();
    this.header.classList.add('header--grey-image');
    this.pillarButton?.classList.add('pillow-scroll-list__item__link--active');
    this.showElementIfExists(this.pillarExplanation);
    this.showElementIfExists(this.birthDayGroup);
    this.showElementIfExists(this.annualDepositGroup);
    this.investmentCategory.value = '3A';
    this.requiredInputs.value =
      'investment-category,birth-day-input,annual-deposit-input';
    this.updateCalculatorUrl();
    this.showOverlayButton.querySelector('p').textContent = this.showOverlayButton.getAttribute('data-text-calculator');
    this.showOverlayButtonNoParams.textContent = this.showOverlayButton.getAttribute('data-text-calculator');
  }
  showFZFields() {
    this.refreshCalculator();
    this.header.classList.add('header--blue');
    this.permissiveButton?.classList.add('pillow-scroll-list__item__link--active');
    this.showElementIfExists(this.permissiveExplanation);
    this.showElementIfExists(this.birthDayGroup);
    this.showElementIfExists(this.transferredCapitalGroup);
    this.investmentCategory.value = 'FZ';
    this.requiredInputs.value =
      'investment-category,birth-day-input,transfered-capital-input';
    this.updateCalculatorUrl();
    this.showOverlayButton.querySelector('p').textContent = this.showOverlayButton.getAttribute('data-text-calculator');
    this.showOverlayButtonNoParams.textContent = this.showOverlayButton.getAttribute('data-text-calculator');
  }
  show3BFields() {
    this.refreshCalculator();
    this.header.classList.add('header--red');
    this.investButton?.classList.add('pillow-scroll-list__item__link--active');
    this.showElementIfExists(this.investExplanation);
    this.showElementIfExists(this.seedCapitalGroup);
    this.investmentCategory.value = '3B';
    this.requiredInputs.value = 'investment-category,seed-capital-input';
    this.updateCalculatorUrl();
    this.showOverlayButton.querySelector('p').textContent = this.showOverlayButton.getAttribute('data-text-simulator');
    this.showOverlayButtonNoParams.textContent = this.showOverlayButton.getAttribute('data-text-simulator');
  }
}

StartCalculatorComponent.declare('.start-calculator');
