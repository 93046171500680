import { Component } from '../../../libs/components';
import { DESKTOP_WIDTH } from '../../constants';
import { handleMobileDropdown } from './nav';


class NavBlogComponent extends Component {
    previousScroll = 0;

    onInit(): void {
        if (window.screen.width >= DESKTOP_WIDTH) {
            this.checkForHiddenBlogNav();
            window.onscroll = () => this.checkForHiddenBlogNav();
        }

        this.host.querySelectorAll('.nav__toggler').forEach((el: HTMLElement) => {
            el.addEventListener('click', this.toggleMobileNav.bind(this));
        });

        this.host.querySelectorAll('.nav__button-link').forEach((el: HTMLElement) => {
            el.addEventListener('click', () => {
                handleMobileDropdown(el.closest('.dropdown'));
            });
        });
    }

    toggleMobileNav() {
        this.host.classList.toggle('nav--show-mobile');
    }

    checkForHiddenBlogNav() {
        if (window.scrollY > 300 && window.scrollY > this.previousScroll && window.screen.width >= DESKTOP_WIDTH) {
            const topNavHeight = this.host.querySelector('.blog-header__top').getBoundingClientRect().height;
            let val = "-100%";
            if (topNavHeight) {
                val = `-${topNavHeight}px`;
            }
            this.host.style.transform = `translateY(${val})`;
        } else {
            this.host.style.transform = "translateY(0)";
        }

        if (window.screen.width >= DESKTOP_WIDTH && window.scrollY > 100) {
            this.host.classList.add('blog-header--shadowed');
        } else {
            this.host.classList.remove('blog-header--shadowed');
        }

        this.previousScroll = window.scrollY;
    }
}
NavBlogComponent.declare('.blog-header');