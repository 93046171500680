import { Component } from '../../../libs/components';

export default class SelectComponent extends Component {
  private container: HTMLElement;
  private selectElement: HTMLElement;
  private arrow: HTMLElement;
  private options: HTMLElement;
  private isOpen = false;

  constructor(protected readonly host: HTMLElement) {
    super(host);

    this.container = host;
    this.arrow = host.querySelector('.select__arrow') as HTMLElement;
    this.options = host.querySelector('.select__options') as HTMLElement;
    this.selectElement = host.querySelector('.select__element') as HTMLElement;

    this.selectElement.addEventListener('click', this.toggle.bind(this));
    document.addEventListener('click', this.close.bind(this));
  }

  toggle() {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.arrow.style.transform = 'rotate(180deg)';
      this.options.style.display = 'flex';
    } else {
      this.arrow.style.transform = 'rotate(0deg)';
      this.options.style.display = 'none';
    }
  }

  close(event?: MouseEvent) {
    let allowedToClose = this.isOpen;

    if (event) {
      allowedToClose = this.isOpen && !this.container.contains(event.target as Node);
    }

    if (allowedToClose) {
      this.isOpen = false;
      this.arrow.style.transform = 'rotate(0deg)';
      this.options.style.display = 'none';
    }
  }

  /**
   * Selects an option element, updates the UI, and closes the options menu.
   *
   * @param {HTMLElement} optionEl - The option element to be selected.
   */
  selectOption(optionEl: HTMLElement) {
    const activeClass = 'select__options__option--active';
    const activeOption = this.host.querySelector(`.${activeClass}`);
    const newValue = optionEl.getAttribute('data-id');
    const customChangeEvent = new CustomEvent("selectionChanged", { detail: newValue });

    activeOption?.classList.remove(activeClass);
    optionEl.classList.add(activeClass);

    this.selectElement.textContent = optionEl.textContent;
    this.host.setAttribute('data-selected-id', newValue);
    this.host.dispatchEvent(customChangeEvent);
    this.close();
  }

  onInit(): void {
    this.host.querySelectorAll('.select__options__option').forEach((el: HTMLElement) => {
      el.addEventListener('click', () => {
        if (el.tagName.toLowerCase() == 'div') {
          // Only do this kind of handling when it's a div,
          // since otherwise, it would be handeled by the default
          // behaviour of the browser.
          this.selectOption(el);
        }
      });
    });
  }
}

SelectComponent.declare('.select');
