import { Component } from '../../../libs/components';

export class SwitchButtonsComponent extends Component {
    buttons = this.host.querySelectorAll<HTMLElement>('.switch-buttons__button');
    selected = this.host.querySelector<HTMLElement>('.switch-buttons__selected');
    active = 0;

    onInit() {
        this.buttons.forEach((button, index) => button.addEventListener('click', () => {
            this.active = index;
            update();
            this.handlers.forEach(handler => handler());
        }))

        const update = () => {
            const widths = [];
            this.buttons.forEach(button => {
                button.classList.remove('active');
                widths.push(button.offsetWidth);
            })
            const position = widths.filter((_, index) => index < this.active).reduce((curr, sum) => curr + sum, 0);

            this.buttons[this.active].classList.add('active');
            this.selected.style.width = `${widths[this.active]}px`;
            this.selected.style.transform = `translateX(${position}px)`;
        }

        update();
    }
    getSelectedValue = () => {
        return this.buttons[this.active].dataset.value;
    }
    handlers: Array<() => void> = [];
    registerHandler = (callback: () => void) => {
        this.handlers.push(callback);
    }
}

SwitchButtonsComponent.declare('.switch-buttons');